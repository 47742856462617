import { render, staticRenderFns } from "./MultiTree.vue?vue&type=template&id=b620cc26&scoped=true"
import script from "./MultiTree.vue?vue&type=script&lang=js"
export * from "./MultiTree.vue?vue&type=script&lang=js"
import style0 from "./MultiTree.vue?vue&type=style&index=0&id=b620cc26&prod&scoped=true&lang=css"
import style1 from "./MultiTree.vue?vue&type=style&index=1&id=b620cc26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../school_end/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b620cc26",
  null
  
)

export default component.exports