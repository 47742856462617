import { render, staticRenderFns } from "./MultiTreeX.vue?vue&type=template&id=16e976c8&scoped=true"
import script from "./MultiTreeX.vue?vue&type=script&lang=js"
export * from "./MultiTreeX.vue?vue&type=script&lang=js"
import style0 from "./MultiTreeX.vue?vue&type=style&index=0&id=16e976c8&prod&scoped=true&lang=css"
import style1 from "./MultiTreeX.vue?vue&type=style&index=1&id=16e976c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../school_end/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e976c8",
  null
  
)

export default component.exports